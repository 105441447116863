var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container",
    attrs: {
      "id": "login"
    }
  }, [_c('div', {
    staticClass: "_max480"
  }, [_vm.userLogin.isLoadingUserSignOut ? _c('div', {
    staticClass: "card"
  }, [_c('h2', [_vm._v("Abmelden...")]), _vm._m(0)]) : _vm.userLogin.isLoadingUserLoginFb || _vm.userLogin.isLoadingUserLoginCrm || _vm.userLogin.isSignedUpFb || _vm.userLogin.isSignedUpCrm ? _c('div', {
    staticClass: "card"
  }, [_c('span', [_c('h2', [_vm._v("Anmelden")]), _vm.userLogin.isLoadingUserLoginFb || _vm.userLogin.isLoadingUserLoginCrm ? _c('div', {
    staticClass: "padding"
  }, [_vm._m(1)]) : _vm._e(), _c('div', {
    staticClass: "padding"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v("Firebase Daten:")]), _vm.userLogin.isLoadingUserLoginFb ? _c('span', {
    staticClass: "fr"
  }, [_c('div', {
    staticClass: "loading-spinner inline"
  })]) : !_vm.userLogin.isLoadingUserLoginFb && _vm.userLogin.isSignedUpFb ? _c('span', {
    staticClass: "fr"
  }, [_c('i', {
    staticClass: "icon-check"
  })]) : !_vm.userLogin.isSignedUpFb ? _c('span', {
    staticClass: "fr"
  }, [_vm._v("kein Benutzerkonto verfügbar"), _c('i', {
    staticClass: "icon-times"
  })]) : _vm._e()]), _c('div', {
    staticClass: "padding"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v("CRM Daten:")]), _vm.userLogin.isLoadingUserLoginCrm ? _c('span', {
    staticClass: "fr"
  }, [_c('div', {
    staticClass: "loading-spinner inline"
  })]) : !_vm.userLogin.isLoadingUserLoginCrm && _vm.userLogin.isSignedUpCrm ? _c('span', {
    staticClass: "fr"
  }, [_c('i', {
    staticClass: "icon-check"
  })]) : !_vm.userLogin.isSignedUpCrm ? _c('span', {
    staticClass: "fr"
  }, [_c('i', {
    staticClass: "icon-times"
  })]) : _vm._e()]), _c('div', {
    staticClass: "padding tac"
  }, [_c('div', {
    staticClass: "btn _red",
    on: {
      "click": _vm._logOut
    }
  }, [_vm._v("Abmelden")])])])]) : _c('div', {
    staticClass: "card"
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("@/assets/img/logo.png")
    }
  }), _c('h1', {
    staticClass: "tac"
  }, [_vm._v("LOGIN")]), _c('form', {
    attrs: {
      "onsubmit": "return false"
    },
    on: {
      "submit": _vm.FBLogin
    }
  }, [_c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _small"
  }, [_vm._v("Email:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.signInData.email,
      expression: "signInData.email"
    }],
    staticClass: "input",
    attrs: {
      "type": "email",
      "placeholder": "email",
      "name": "email"
    },
    domProps: {
      "value": _vm.signInData.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.signInData, "email", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _small"
  }, [_vm._v("Password:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.signInData.password,
      expression: "signInData.password"
    }],
    staticClass: "input",
    attrs: {
      "type": "password",
      "placeholder": "password",
      "name": "password"
    },
    domProps: {
      "value": _vm.signInData.password
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.signInData, "password", $event.target.value);
      }
    }
  })]), _vm._m(2)])]), _vm.userLogin.isSignedUpFb && _vm.userLogin.isSignedUpCrm ? _c('div', {
    staticClass: "card"
  }, [_vm._m(3)]) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "padding"
  }, [_c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "loading-spinner _large"
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tac"
  }, [_c('p', [_vm._v("Bitte warten")]), _c('div', {
    staticClass: "loading-spinner _large"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tac"
  }, [_c('input', {
    staticClass: "btn",
    attrs: {
      "type": "submit",
      "value": "Anmelden"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card-content tac"
  }, [_c('p', {
    staticClass: "bold"
  }, [_vm._v("Bitte in der Seitenleiste Links auswählen.")])]);
}];
export { render, staticRenderFns };